import { dropWhileRight } from './array.js'

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(graphemes("cat"), ["c", "a", "t"])
 * t.deepEqual(graphemes(""), [])
 * ```
 */
export function graphemes(str) {
  return Array.from(str)
}

/*
 * @doctests
 *
 * ```js
 * t.is(pascalize("hello_world"), "HelloWorld")
 * t.is(pascalize(""), "")
 * ```
 * snake case to pascal case
 */
export function pascalize(str) {
  return str.split('_').map(capitalize).join('')
}

/*
 * @doctests
 *
 * ```js
 * t.is(toSpinalCase("hello world"), "hello-world")
 * t.is(toSpinalCase("HI_THERE"), "hi-there")
 * t.is(toSpinalCase("One_Two 3"), "one-two-3")
 * t.is(toSpinalCase("hello world"), "hello-world")
 * t.is(toSpinalCase(""), "")
 * ```
 * to spinal case
 */
export function toSpinalCase(str) {
  return str
    .toLowerCase()
    .split(/[\W_]+/)
    .join('-')
}

/*
 * @doctests
 *
 * ```js
 * t.is(snakeToTitleCase("hello_world"), "Hello World")
 * t.is(snakeToTitleCase("HI_THERE"), "Hi There")
 * t.is(snakeToTitleCase("One_Two_3"), "One Two 3")
 * t.is(snakeToTitleCase(""), "")
 * ```
 * snake case to title case
 */
export function snakeToTitleCase(str) {
  return str.toLowerCase().split(/[_]/).map(capitalize).join(' ')
}

/*
 * @doctests
 *
 * ```js
 * t.is(capitalize("cat"), "Cat")
 * t.is(capitalize(""), "")
 * t.is(capitalize("CAT"), "CAT")
 * ```
 */
export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/*
 * @doctests
 *
 * ```js
 * t.is(uncamelCase("helloWorld"), "hello World")
 * t.is(uncamelCase("hello"), "hello")
 * t.is(uncamelCase(""), "")
 * t.is(uncamelCase("camelCaseWord"), "camel Case Word")
 * ```
 */
export function uncamelCase(str) {
  return str.replaceAll(/([a-z])([A-Z])/g, '$1 $2')
}

/*
 * @doctests
 *
 * ```js
 * t.is(unsnakeCase("hello_world"), "hello world")
 * t.is(unsnakeCase("hello__world"), "hello world")
 * t.is(unsnakeCase("hello"), "hello")
 * t.is(unsnakeCase(""), "")
 * t.is(unsnakeCase("camel_case_word"), "camel case word")
 * ```
 */
export function unsnakeCase(str) {
  return str.replaceAll(/_+/g, ' ')
}

/*
 * doesn't belong here, oh well
 * @doctests
 *
 * ```js
 * t.deepEqual([1,3,2,5,4,7,6,12,8].sort(intcmp), [1,2,3,4,5,6,7,8,12])
 * t.deepEqual([1,3,2,5,4,7,6,8].sort((a, b) => intcmp(b, a)), [8,7,6,5,4,3,2,1])
 * ```
 */
export function intcmp(a, b) {
  return a < b ? -1 : a === b ? 0 : 1
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(['2.3', '2', '1.5', '1.205'].sort(numSortBy(parseFloat)), ['1.205', '1.5', '2', '2.3'])
 * t.deepEqual([3, 1, 2].sort(numSortBy(parseFloat)), [1, 2, 3])
 * t.deepEqual(['$1.5', '$1.2005', '0.228'].sort(numSortBy((x) => parseFloat(x.replace(/[^0-9.]/g, '')) || 0)), ['0.228', '$1.2005', '$1.5'])
 * ```
 */
export function numSortBy(fxn) {
  return (a, b) => intcmp(fxn(a), fxn(b))
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual([{name: "99.99"}, {name: "99.9"}].sort(numSortOn('name')), [{name: "99.9"}, {name: "99.99"}])
 * ```
 */
export const numSortOn = (key) => numSortBy((obj) => obj[key])

/*
 * @doctests
 *
 * ```js
 * t.is(boolcmp(true, true), 0)
 * t.is(boolcmp(false, true), -1)
 * ```
 */
export function boolcmp(a, b) {
  return a === b ? 0 : -1
}

/*
 * @doctests
 *
 * ```js
 * t.is(strcmp('2', '10'), -1)
 * t.is(strcmp('a', 'b'), -1)
 * t.is(strcmp(undefined, 'zzzzz'), 1)
 * t.is(strcmp('zzzzz', undefined), -1)
 * ```
 */
export function strcmp(a, b) {
  // undefined and null always greatest
  if (a == null) return 1
  if (b == null) return -1
  return a.toString().localeCompare(b, 'en', {
    sensitivity: 'base',
    numeric: 'true'
  })
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(inputAsInt('44'), {value: 44})
 * t.deepEqual(inputAsInt('jjj44jjj'), {value: 44})
 * t.deepEqual(inputAsInt('jj 4 jj 4 jj'), {value: 44})
 * t.deepEqual(inputAsInt('hello'), {value: 0})
 * t.deepEqual(inputAsInt('2147483647'), {value: 2147483646})
 * ```
 */
const pgMax = 2147483646
export function inputAsInt(input) {
  let cvalue = `${input.replace(/[^0-9]/g, '')}`
  if (cvalue === '') {
    cvalue = '0'
  }
  let value = parseInt(cvalue, 10)
  if (value >= pgMax) {
    value = pgMax
  }
  return { value }
}

/*
 * @doctests
 *
 * ```js
 * t.is(plural(0, "house"), "0 houses")
 * t.is(plural(1, "house"), "1 house")
 * t.is(plural(2, "house"), "2 houses")
 * ```
 */
export function plural(number, label) {
  return `${number || 0} ${label}${number !== 1 ? 's' : ''}`
}

/*
 * @doctests
 *
 * ```js
 * t.is(sizeOf(4500), "4 Kb")
 * t.is(sizeOf(1000000), "976 Kb")
 * t.is(sizeOf(3000000), "2 Mb")
 * t.is(sizeOf(5_000_000_000), "4 Gb")
 * ```
 */
export function sizeOf(bytes) {
  if (bytes > 1024 * 1024 * 1024) {
    return `${Math.floor(bytes / 1024 / 1024 / 1024)} Gb`
  } else if (bytes > 1024 * 1024) {
    return `${Math.floor(bytes / 1024 / 1024)} Mb`
  } else if (bytes > 1024) {
    return `${Math.floor(bytes / 1024)} Kb`
  } else {
    return `${bytes} b`
  }
}

/*
 * @doctests
 *
 * ```js
 * t.is(ellipsisTruncate("house", 4), "hou…")
 * t.is(ellipsisTruncate("house", 10), "house")
 * ```
 */
export function ellipsisTruncate(word, maxLen) {
  word = word || ''
  return word.length > maxLen ? word.slice(0, maxLen - 1) + '…' : word
}

// for float strings use number.toFixed(precision)

/*
 * @doctests
 *
 * ```js
 * t.is(joinPath('cat', 'food'), "cat/food")
 * t.is(joinPath('/cat/', '/food/'), "/cat/food/")
 * t.is(joinPath('/cat//food'), "/cat/food")
 * t.is(joinPath('/a/', 'b', undefined), "/a/b")
 * ```
 */
export function joinPath(...path) {
  return dropWhileRight((seg) => seg == null, path)
    .join('/')
    .replace(/\/+/g, '/')
}

export const cleanPath = joinPath

/*
 * @doctests
 *
 * ```js
 * t.truthy(detectNaN(10 / undefined))
 * t.truthy(detectNaN(NaN))
 * t.truthy(detectNaN("NaN"))
 * t.falsy(detectNaN("cat"))
 * t.falsy(detectNaN({}))
 * t.falsy(detectNaN([1]))
 * t.falsy(detectNaN(Infinity))
 * t.falsy(detectNaN("10"))
 * ```
 */
export function detectNaN(maybeNaN) {
  switch (typeof maybeNaN) {
    case 'number':
      return isNaN(maybeNaN)
    case 'string':
      return maybeNaN.includes('NaN')
    default:
      return false
  }
}

/*
 * @doctests
 *
 * ```js
 * t.is(stringify(34), "34")
 * t.is(stringify({}), "{}")
 * t.is(stringify("cat"), "cat")
 * ```
 */
export function stringify(val) {
  switch (typeof val) {
    case 'string':
      return val
    case 'object':
      return JSON.stringify(val)
    default:
      return String(val)
  }
}

/*
 * @doctests
 *
 * ```js
 * t.is(unstringify(34), 34)
 * t.is(unstringify("17"), 17)
 * t.is(unstringify("12.5"), 12.5)
 * t.is(unstringify(null), null)
 * t.is(unstringify("cats"), "cats")
 * t.deepEqual(unstringify('{"cat":"man"}'), {cat: 'man'})
 * t.deepEqual(unstringify('["cat","man"]'), ['cat', 'man'])
 * t.deepEqual(unstringify('[1,2,3]'), [1,2,3])
 * ```
 */
export function unstringify(str) {
  try {
    return JSON.parse(str)
  } catch (err) {
    return str
  }
}

/*
 * @doctests
 *
 * ```js
 * t.truthy(isHex('0'))
 * t.truthy(isHex('2'))
 * t.truthy(isHex('9'))
 * t.truthy(isHex('a'))
 * t.truthy(isHex('A'))
 * t.truthy(isHex('f'))
 * t.truthy(isHex('F'))
 * t.falsy(isHex('r'))
 * t.falsy(isHex('G'))
 * t.falsy(isHex('v'))
 * t.falsy(isHex('-'))
 * t.falsy(isHex('+'))
 * t.falsy(isHex(','))
 * t.falsy(isHex('/'))
 * t.falsy(isHex('!'))
 * ```
 */
export function isHex(char) {
  return /[0-9a-f]/i.test(char)
}

/*
 * @doctests
 *
 * ```js
 * t.truthy(isHexDelimeter(':'))
 * t.truthy(isHexDelimeter('-'))
 * t.truthy(isHexDelimeter('.'))
 * t.falsy(isHexDelimeter('1'))
 * t.falsy(isHexDelimeter(' '))
 * t.falsy(isHexDelimeter('/'))
 * t.falsy(isHexDelimeter('+'))
 * t.falsy(isHexDelimeter('4'))
 * t.falsy(isHexDelimeter('!'))
 * t.falsy(isHexDelimeter('"'))
 * t.falsy(isHexDelimeter('_'))
 * t.falsy(isHexDelimeter('\\'))
 * ```
 */
export function isHexDelimeter(char) {
  return /[.\-:]/.test(char)
}

/*
 * @doctests
 *
 * ```js
 * t.truthy(isWhitespace(' '))
 * t.truthy(isWhitespace('\n'))
 * t.truthy(isWhitespace('\t'))
 * t.falsy(isWhitespace('e'))
 * t.falsy(isWhitespace('2'))
 * t.falsy(isWhitespace('-'))
 * t.falsy(isWhitespace('.'))
 * t.falsy(isWhitespace('*'))
 * ```
 */
export function isWhitespace(char) {
  return /\s/.test(char)
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(parseMacAddress('00:25:96:12:34:56'), {success: true, mac: '00:25:96:12:34:56', rest: ''})
 * t.deepEqual(parseMacAddress('002.596.123.456'), {success: true, mac: '00:25:96:12:34:56', rest: ''})
 * t.deepEqual(parseMacAddress('00-25-96-12-34-56'), {success: true, mac: '00:25:96:12:34:56', rest: ''})
 * t.deepEqual(parseMacAddress('00 af 8e 4a 99 12'), {success: true, mac: '00:AF:8E:4A:99:12', rest: ''})
 * t.deepEqual(parseMacAddress('00 aj 8e 4a 99 12'), {success: false, mac: '00a', rest: 'j 8e 4a 99 12'})
 * t.deepEqual(parseMacAddress('1234567ABCDEFG'), {success: true, mac: '12:34:56:7A:BC:DE', rest: 'FG'})
 * ```
 */
export function parseMacAddress(maybeMac) {
  const macLength = 12
  return graphemes(maybeMac).reduce(
    (acc, char) => {
      let { mac, success, rest } = acc
      if (typeof success === 'boolean') return { ...acc, rest: rest + char }
      if (isHexDelimeter(char)) return acc
      if (isWhitespace(char)) return acc
      if (isHex(char)) {
        mac += char
        const done = mac.length === macLength
        if (done) {
          mac = formatMac(mac)
        }
        return { ...acc, mac, success: done ? true : undefined }
      } else {
        return {
          ...acc,
          success: false,
          rest: char
        }
      }
    },
    { success: undefined, mac: '', rest: '' }
  )
}

/*
 * @doctests
 *
 * ```js
 * t.is(formatMac('002596123456'), '00:25:96:12:34:56')
 * ```
 */
export function formatMac(mac) {
  return mac
    .toUpperCase()
    .match(/.{1,2}/g)
    .join(':')
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(parseMac('00:25:96:12:34:56'), {success: true, mac: '002596123456', reason: undefined})
 * t.deepEqual(parseMac('00 aj 8e 4a 99 12'), {success: false, mac: '00a8e4a9912', reason: 'mac has fewer than 12 characters'})
 * t.deepEqual(parseMac('1234567ABCDEFG123'), {success: false, mac: '1234567ABCDEF123', reason: 'mac has more than 12 characters'})
 * ```
 */
export function parseMac(maybeMac) {
  const mac = maybeMac.replace(/[^0-9a-f]/gi, '')
  if (mac.length === 12) {
    return { success: true, mac: mac, reason: undefined }
  }

  return {
    success: false,
    mac,
    reason: `mac has ${mac.length > 12 ? 'more' : 'fewer'} than 12 characters`
  }
}

/*
 * @doctests
 *
 * ```js
 * t.truthy(isCIDR('0.0.0.0'))
 * t.falsy(isCIDR('0.0.0'))
 * t.falsy(isCIDR('1.2.3.4.5'))
 * t.falsy(isCIDR(null))
 * t.falsy(isCIDR(undefined))
 * ```
 */
export function isCIDR(publicIp) {
  return /^\d+\.\d+\.\d+\.\d+$/.test(publicIp)
}

/*
 * @doctests
 *
 * ```js
 * t.truthy(isMacAddress('3e:3f:88:aB:2d:1b'))
 * t.truthy(isMacAddress('bE:70:8c:d0:2D:1a'))
 * t.truthy(isMacAddress('3e:3f:88:11:2d:19'))
 * t.falsy(isMacAddress('3e:3g:88:aB:2d:1q'))
 * t.falsy(isMacAddress('bE:70:8c:dj:2D:1a'))
 * t.falsy(isMacAddress('3e:yf:88:1z:2d:19'))
 * t.falsy(isMacAddress('e:3S:88:PO:2d:1q'))
 * t.falsy(isMacAddress('3e:3S:88:PO:2d:1q:12'))
 * t.falsy(isMacAddress('3e:3S:88:PO:2d'))
 * t.falsy(isMacAddress('3e:3s:@@:po:2d:1q'))
 * t.falsy(isMacAddress('3e:3s:88:_o:2d:1q'))
 * ```
 */
export function isMacAddress(maybeMac) {
  return /^([a-fA-F0-9]{2}:){6}$/.test(maybeMac + ':')
}

/*
 * @doctests
 *
 * ```js
 * t.truthy(isSerialNumber('DJIJE37HD7H22Q09'))
 * t.truthy(isSerialNumber('09UD8D76D6D5FC'))
 * t.falsy(isSerialNumber('343HD@#899898'))
 * t.falsy(isSerialNumber(''))
 * t.falsy(isSerialNumber('hello-world'))
 * ```
 */
export function isSerialNumber(maybeSerialNumber) {
  return /^\w+$/.test(maybeSerialNumber)
}

/*
 * @doctests
 *
 * ```js
 * t.is(nbsp(3), '   ')
 * t.is(nbsp(), ' ')
 * ```
 */
export function nbsp(num = 1) {
  return Array(num).fill(' ').join('')
}

/*
 * @doctests
 *
 * ```js
 * t.is(shortIdEq('15ad6', '15ad647d-788f-4ec8-8d9e-fda2cfd6a866'), true)
 * t.is(shortIdEq('15ad647d-788f-4ec8-8d9e-fda2cfd6a866', '15ad647d-788f-4ec8-8d9e-fda2cfd6a866'), true)  // full length compare is fine
 * t.is(shortIdEq('15', '15ad647d-788f-4ec8-8d9e-fda2cfd6a866'), false)  // not long enough to count
 * ```
 */
export function shortIdEq(shortId, longId) {
  return shortId.length >= 5 && longId.startsWith(shortId)
}

/*
 * @doctests
 *
 * ```js
 * t.falsy(isNotId('15ad6'))
 * t.falsy(isNotId('15ad647d-788f-4ec8-8d9e-fda2cfd6a866'))
 * t.falsy(isNotId('15'))
 * t.truthy(isNotId(' '))
 * t.truthy(isNotId(undefined))
 * t.truthy(isNotId('_'))
 * ```
 */
export function isNotId(s) {
  return !s?.trim() || s === '_'
}

/*
 * @doctests
 *
 * ```js
 * t.truthy(isEmail('good@email.com'))
 * t.truthy(isEmail('a@b.c'))
 * t.truthy(isEmail('hello+@world.net'))
 * t.truthy(isEmail('example@email.gmail'))
 * t.falsy(isEmail('@example.com'))
 * t.falsy(isEmail('example@email'))
 * t.falsy(isEmail('example@at@.com'))
 * t.falsy(isEmail('example@.com'))
 * ```
 */
export function isEmail(email) {
  return /^[^@]+@[^@]+\.[a-z]+$/.test(email)
}

/*
 * @doctests
 *
 * ```js
 * t.is(priceToFloat('$23.3'), 23.3)
 * t.is(priceToFloat('¢13'), 13.0)
 * t.is(priceToFloat(7.7), 7.7)
 * t.is(priceToFloat('USD 90_000.12'), 90000.12)
 * ```
 */
export function priceToFloat(price) {
  return parseFloat(('0' + price).replace(/[^0-9.]/g, ''))
}

/*
 * @doctests
 *
 * ```js
 * t.is(unwords("cat", "man", "house"), "cat man house")
 * ```
 */
export function unwords(...words) {
  return words.join(' ')
}
